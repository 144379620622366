var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button } from 'semantic-ui-react';
import './Launch.css';
import clientConfig from './client.json';
var client = clientConfig;
var ClientJson = /** @class */ (function () {
    function ClientJson() {
        this.description = 'description';
        this.title = 'title';
    }
    return ClientJson;
}());
export var LaunchView = function (props) {
    return (_jsxs("div", __assign({ id: "launchContainer" }, { children: [_jsxs("div", { children: [_jsx("h1", { children: client.description }), _jsx(Button, { size: "massive", color: "blue", circular: true, icon: "play", onClick: function () { return props.Launch(); } })] }), _jsx("img", { alt: "PureWeb Logo", src: "/pureweb.svg", style: { width: 100, position: 'absolute', bottom: 35, right: 40 } })] })));
};
